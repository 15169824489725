import { SurveyQuestionEditorDefinition } from "survey-creator-core";
import * as SurveyCore from "survey-core";
import ItemValueCommon from "../../common/component-collection/ItemValueCommon";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";
import i18next from "../../common/localization/i18n";

type Option = {
  value: string;
  text: string;
};
const Subscriptions: { [option: string]: Option[] } = {};
export function SetSubscriptionsChoices(value: string, options: Option[]) {
  if (!Subscriptions[value]) {
    Subscriptions[value] = options;
  }
}

export default function ItemValue(subscriptionsStateParams: SubscriptionsStateParams) {
  SurveyQuestionEditorDefinition.definition["itemvalue[]@choices"].tabs = [
    { name: "subscriptionListMap", visible: true },
    { name: "value", visible: true },
    { name: "trueMap", visible: true },
    { name: "falseMap", visible: true },
  ];

  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "subscriptionListMap",
    type: "dropdown",
    displayName: i18next.t("NEW_FORM_EDITOR.ITEM_VALUE.SUBSCRIPTION_LIST"),
    isSerializable: true,
    showMode: "form",
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      // eslint-disable-next-line no-underscore-dangle
      const selectedAttribute = obj?._locOwner?.subscriptionsType;
      if (selectedAttribute) {
        /* if (selectedAttribute === "email") {
          SetMapSubscriptionAttributes(choicesCallback, subscriptionsStateParams, "email");
        }
        if (selectedAttribute === "phone") {
          SetMapSubscriptionAttributes(choicesCallback, subscriptionsStateParams, "phone");
        } */
        choicesCallback(Subscriptions[selectedAttribute]);
      }
    },
  });

  ItemValueCommon(subscriptionsStateParams);
}
