import { surveyLocalization } from "survey-core";
import * as SurveyCreatorCore from "survey-creator-core";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";

function SurveyLocalization(routeParams: SubscriptionsStateParams) {
  if (routeParams.userlcid === "1036") {
    /*  SurveyCreatorCore.localization.getLocale("fr").pe.tabs.toolboxMapperCategory = "FR_Mapper";
    SurveyCreatorCore.localization.getLocale("fr").pe.tabs.toolboxCaptchaCategory = "FR_Captcha";
    SurveyCreatorCore.localization.getLocale("fr").qt.email_field = "FR_Email";
    SurveyCreatorCore.localization.getLocale("fr").qt.phone_field = "FR_Phone";
    surveyLocalization.locales.fr.completingSurvey = "FR_Thank you for completing the form";
    surveyLocalization.locales.fr.loadingSurvey = "FR_Loading Form...";
    surveyLocalization.locales.fr.questionTitlePatternText = "FR_Field Title";
    surveyLocalization.locales.fr.completingSurveyBefore =
      "FR_Our records show that you have already completed this form.";
            */
  } else {
    SurveyCreatorCore.localization.getLocale("en").pe.tabs.toolboxMapperCategory = "Mapper";
    SurveyCreatorCore.localization.getLocale("en").pe.tabs.toolboxCaptchaCategory = "Captcha";
    SurveyCreatorCore.localization.getLocale("en").qt.email_field = "Email";
    SurveyCreatorCore.localization.getLocale("en").qt.phone_field = "Phone";
    surveyLocalization.locales.en.completingSurvey = "Your preference has been saved";
    surveyLocalization.locales.en.loadingSurvey = "Loading page...";
    surveyLocalization.locales.en.questionTitlePatternText = "Field Title";
    surveyLocalization.locales.en.completingSurveyBefore =
      "Our records show that you have already completed this form.";
  }
}

export default SurveyLocalization;
