import { surveyLocalization } from "survey-core";
import * as SurveyCreatorCore from "survey-creator-core";
import FormStateParams from "../interfaces/FormStateParams";

function SurveyLocalization(routeParams: FormStateParams) {
  if (routeParams.userlcid === "1036") {
    /*  SurveyCreatorCore.localization.getLocale("fr").pe.tabs.toolboxMapperCategory = "FR_Mapper";
    SurveyCreatorCore.localization.getLocale("fr").pe.tabs.toolboxCaptchaCategory = "FR_Captcha";
    surveyLocalization.locales.fr.completingSurvey = "FR_Thank you for completing the form";
    surveyLocalization.locales.fr.loadingSurvey = "FR_Loading Form...";
    surveyLocalization.locales.fr.questionTitlePatternText = "FR_Field Title";
    surveyLocalization.locales.fr.completingSurveyBefore =
      "FR_Our records show that you have already completed this form."; */
  } else {
    SurveyCreatorCore.localization.getLocale("en").pe.tabs.toolboxMapperCategory = "Mapper";
    SurveyCreatorCore.localization.getLocale("en").pe.tabs.toolboxCaptchaCategory = "Captcha";
    surveyLocalization.locales.en.completingSurvey = "Thank you for completing the form";
    surveyLocalization.locales.en.loadingSurvey = "Loading Form...";
    surveyLocalization.locales.en.questionTitlePatternText = "Field Title";
    surveyLocalization.locales.en.completingSurveyBefore =
      "Our records show that you have already completed this form.";
  }
}

export default SurveyLocalization;
