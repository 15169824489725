import * as SurveyCore from "survey-core";
import i18next from "../localization/i18n";
import Preference from "../data/Preference";
import FormStateParams from "../../form-editor/interfaces/FormStateParams";
import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";

export default function ItemValueCommon(stateParams: FormStateParams | SubscriptionsStateParams) {
  SurveyCore.Serializer.findClass("itemvalue").findProperty("visibleIf").visible = false;
  SurveyCore.Serializer.findClass("itemvalue").findProperty("value").showMode = "form";
  SurveyCore.Serializer.findClass("itemvalue").findProperty("enableIf").visible = false;

  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "trueMap",
    type: "dropdown",
    displayName: i18next.t("EDITORS_COMMON.ITEM_VALUE.TRUE"),
    isSerializable: true,
    showMode: "form",
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      choicesCallback(Preference);
    },
  });
  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "falseMap",
    type: "dropdown",
    displayName: i18next.t("EDITORS_COMMON.ITEM_VALUE.FALSE"),
    isSerializable: true,
    showMode: "form",
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      choicesCallback(Preference);
    },
  });
}
