import { SurveyCreator } from "survey-creator-react";

function OrderToolbox(surveyCreator: SurveyCreator) {
  surveyCreator.toolbox.orderedQuestions = [
    "email_field",
    "phone_field",
    "checkbox",
    "consent",
    "image",
    "html",
  ];
}

export default OrderToolbox;
