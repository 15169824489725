export default function OnElementAllowOperations(sender: any, options: any) {
  if (options.element?.crmFieldType === "email") {
    options.allowCopy = false;
  }
  if (options.element?.crmFieldType === "phone") {
    options.allowCopy = false;
  }
  if (options.element?.crmFieldType === "subscriptions") {
    options.allowCopy = false;
  }
}
