import { DialogType } from "../../../common/enums/DialogType";

export default async function CheckUnmappedFieldsButton(surveyCreator: any, dialog: any) {
  const unmappedFields: string[] = [];

  surveyCreator.survey.getAllQuestions().map((q: any) => {
    if (!q.leadMap && !q.contactMap) {
      unmappedFields.push(q.name);
    }
    return null;
  });

  let bodyMessage = "All fields are mapped correctly.";
  if (unmappedFields.length > 0) {
    bodyMessage =
      "Warning. The below fields are not yet mapped. Please map them to a corresponding CRM field to automatically map data on form submission.";
  }

  if (
    await dialog(
      DialogType.CheckMapping,
      "",
      bodyMessage,
      null,
      Object.create({ fields: unmappedFields }),
    )
  ) {
    return "";
  }
}
