import { useContext } from "react";
import DialogContext from "../store/DialogContext";
import { HIDE_DIALOG, SHOW_DIALOG } from "../store/DialogReducer";
import { DialogType } from "../enums/DialogType";

let resolveCallback: (arg0: boolean) => void;
function useDialog() {
  // @ts-ignore
  const [dialogState, dispatch] = useContext(DialogContext);

  const closeDialog = () => {
    dispatch({
      type: HIDE_DIALOG,
    });
  };
  const onConfirm = () => {
    closeDialog();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeDialog();
    resolveCallback(false);
  };
  const dialog = (
    dialogType: DialogType,
    title?: string,
    text?: any,
    yesButton?: string,
    bodyModel?: any,
  ) => {
    dispatch({
      type: SHOW_DIALOG,
      payload: {
        dialogType,
        text,
        yesButton,
        title,
        bodyModel,
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  return { dialog, onConfirm, onCancel, dialogState };
}

export default useDialog;
