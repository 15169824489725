import * as SurveyCore from "survey-core";
import SetLeadMaxLengthAndValue from "./common/SetLeadMaxLengthAndValue";
import SetContactMaxLengthAndValue from "./common/SetContactMaxLengthAndValue";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import SetMapContactAttributes from "../helpers/SetMapContactAttributes";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import SetMapLeadAttributes from "../helpers/SetMapLeadAttributes";

function CommentToolbox(formStateParams: FormStateParams) {
  SurveyCore.Serializer.addProperty("comment", {
    name: "crmMaxLength",
    type: "number",
    default: 1,
    isSerializable: true,
    visible: false,
  });
  SurveyCore.Serializer.addProperty("comment", {
    name: "crmContactMaxLength",
    type: "number",
    default: 1,
    isSerializable: true,
    visible: false,
  });
  SurveyCore.Serializer.addProperty("comment", {
    name: "crmLeadMaxLength",
    type: "number",
    default: 1,
    isSerializable: true,
    visible: false,
  });
  SurveyCore.Serializer.addProperty("comment", {
    name: "contactMap",
    isSerializable: true,
    visible: false,
    onSetValue: (obj: any, value: any) => {
      if (!obj.getPropertyValue("contactMapTemp")) {
        SetContactMaxLengthAndValue(obj, value);
      }
    },
  });

  SurveyCore.Serializer.addProperty("comment", {
    name: "contactMapTemp",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "dropdown",
    isSerializable: false,
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    onSetValue: (obj: any, value: any) => {
      SetContactMaxLengthAndValue(obj, value);
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      SetMapContactAttributes("textArea", choicesCallback, formStateParams);
    },
    visibleIndex: 1,
  });

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("comment", {
      name: "leadMap",
      isSerializable: true,
      visible: false,
      onSetValue: (obj: any, value: any) => {
        if (!obj.getPropertyValue("leadMapTemp")) {
          SetLeadMaxLengthAndValue(obj, value);
        }
      },
    });

    SurveyCore.Serializer.addProperty("comment", {
      name: "leadMapTemp",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "dropdown",
      isSerializable: false,
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      onSetValue: (obj: any, value: any) => {
        SetLeadMaxLengthAndValue(obj, value);
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        SetMapLeadAttributes("textArea", choicesCallback, formStateParams, obj);
      },
      visibleIndex: 1,
    });
  }

  SurveyCore.Serializer.addProperty("comment", {
    name: "overrideInCrmMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "boolean",
  });
}

export default CommentToolbox;
