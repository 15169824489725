import { Serializer } from "survey-core";

function SurveySerializer() {
  Serializer.addProperty("question", {
    name: "formFieldId:text",
    type: "text",
    default: "",
    isSerializable: true,
    visible: false,
  });

  Serializer.addProperty("question", {
    name: "crmId:text",
    type: "text",
    default: "",
    isSerializable: true,
    visible: false,
  });

  Serializer.findProperty("text", "inputType").setChoices([
    "email",
    "text",
    "url",
    "integer",
    "decimal",
    "money",
  ]);
}

export default SurveySerializer;
