import * as SurveyCore from "survey-core";
import PublishCustomFieldsButton from "./PublishCustomFieldsButton";
import CheckUnmappedFieldsButton from "./CheckUnmappedFieldsButton";
import FormStateParams from "../../interfaces/FormStateParams";

export default function TopBarDropdown(
  surveyCreator: any,
  routeParams: FormStateParams,
  setLoading: any,
  dialog: any,
) {
  const dropdownItems: SurveyCore.IAction[] = [];

  dropdownItems.push({ title: "Publish Custom Fields", id: "publishCustomFields" });
  dropdownItems.push({ title: "Check Mapping", id: "checkMappings" });

  // @ts-ignore
  const isVisible: boolean = new SurveyCore.ComputedUpdater<boolean | undefined>(() => {
    return surveyCreator.activeTab === "designer";
  });

  const customDropdownAction = SurveyCore.createDropdownActionModel(
    {
      id: "customDropdownAction",
      iconName: "icon-vertical-three-dots",
      visible: isVisible,
    },
    {
      items: dropdownItems,
      onSelectionChanged: (item) => {
        if (item.id === "publishCustomFields") {
          PublishCustomFieldsButton(surveyCreator, routeParams, setLoading, dialog);
        }
        if (item.id === "checkMappings") {
          CheckUnmappedFieldsButton(surveyCreator, dialog);
        }
      },
      verticalPosition: "bottom",
      horizontalPosition: "center",
    },
  );
  surveyCreator.toolbar.actions.push(customDropdownAction);
}
