import * as SurveyCore from "survey-core";
import { SetContactChoices, SetLeadChoices } from "./ItemValue";
import GetLeadContactAttributeOptionsThen from "./common/GetLeadContactAttributeOptionsThen";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import GetLeadContactAttributeOptions from "../api/GetLeadContactAttributeOptions";
import GetLeadAttributes from "../api/GetLeadAttributes";
import ContactLeadAttributeModel from "../interfaces/ContactLeadAttributeModel";
import GetContactAttributes from "../api/GetContactAttributes";

function RadioGroupToolbox(formStateParams: FormStateParams, dialog: any) {
  let contactAttributes: { value: string; text: string }[] | undefined;
  let leadAttributes: { value: string; text: string }[] | undefined;

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("radiogroup", {
      name: "leadMap",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "dropdown",
      isSerializable: true,
      isLightSerializable: true,
      categoryIndex: 8,
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      onSetValue: (surveyElement: any, value: any) => {
        if (surveyElement.isDesignMode) {
          GetLeadContactAttributeOptions(formStateParams, "lead", value)?.then((data: any) => {
            GetLeadContactAttributeOptionsThen(
              data,
              surveyElement,
              value,
              SetLeadChoices,
              formStateParams,
              "lead",
              dialog,
            );
          });
        }

        surveyElement.setPropertyValue("leadMap", value);
      },
      choices: (obj: any, choicesCallback: any) => {
        const o = obj;
        if (!choicesCallback) return;
        if (!leadAttributes) {
          o.clearErrors();
          GetLeadAttributes(formStateParams, "list")
            ?.then((data: [ContactLeadAttributeModel]) => {
              leadAttributes = data.map((c: ContactLeadAttributeModel) => {
                return { value: c.schemaName, text: c.displayName };
              });
              choicesCallback(leadAttributes);
            })
            .catch(() => {
              o.clearErrors();
              o.addError("Server error while loading mappings.");
            });
        } else {
          choicesCallback(leadAttributes);
        }
      },
      visibleIndex: 1,
    });
  }

  SurveyCore.Serializer.addProperty("radiogroup", {
    name: "contactMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "dropdown",
    isSerializable: true,
    isLightSerializable: true,
    categoryIndex: 8,
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    onSetValue: (surveyElement: any, value: any) => {
      if (surveyElement.isDesignMode) {
        GetLeadContactAttributeOptions(formStateParams, "contact", value)?.then((data: any) => {
          GetLeadContactAttributeOptionsThen(
            data,
            surveyElement,
            value,
            SetContactChoices,
            formStateParams,
            "contact",
            dialog,
          );
        });
      }

      surveyElement.setPropertyValue("contactMap", value);
    },
    choices: (obj: any, choicesCallback: any) => {
      const o = obj;
      if (!choicesCallback) return;
      if (!leadAttributes) {
        o.clearErrors();
        GetContactAttributes(formStateParams, "list")
          ?.then((data: [ContactLeadAttributeModel]) => {
            contactAttributes = data.map((c: ContactLeadAttributeModel) => {
              return { value: c.schemaName, text: c.displayName };
            });
            choicesCallback(contactAttributes);
          })
          .catch(() => {
            o.clearErrors();
            o.addError("Server error while loading mappings.");
          });
      } else {
        choicesCallback(contactAttributes);
      }
    },
    visibleIndex: 1,
  });

  SurveyCore.Serializer.addProperty("radiogroup", {
    name: "overrideInCrmMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "boolean",
    categoryIndex: 8,
  });
}

export default RadioGroupToolbox;
