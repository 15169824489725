import * as SurveyCore from "survey-core";
import { SurveyQuestionEditorDefinition } from "survey-creator-core";
import SubscriptionType from "../data/SubscriptionType";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import ItemValueCommon from "../../common/component-collection/ItemValueCommon";
import SetMapSubscriptionAttributes from "../../common/helpers/SetMapSubscriptionAttributes";

type Option = {
  value: string;
  text: number;
};
const LeadOptionsAttributes: { [option: string]: Option[] } = {};
const ContactOptionsAttributes: { [option: string]: Option[] } = {};
export function SetContactChoices(value: string, options: Option[]) {
  if (!ContactOptionsAttributes[value]) {
    ContactOptionsAttributes[value] = options;
  }
}

export function SetLeadChoices(value: string, options: Option[]) {
  if (!LeadOptionsAttributes[value]) {
    LeadOptionsAttributes[value] = options;
  }
}
function ItemValue(formStateParams: FormStateParams) {
  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyQuestionEditorDefinition.definition["itemvalue[]@choices"].tabs = [
      { name: "value", visible: true },
      { name: "contactAttributeOptionMap", visible: true },
      { name: "leadAttributeOptionMap", visible: true },
      { name: "subscriptionListMap", visible: true },
      { name: "subscriptionType", visible: true },
      { name: "trueMap", visible: true },
      { name: "falseMap", visible: true },
    ];
  } else {
    SurveyQuestionEditorDefinition.definition["itemvalue[]@choices"].tabs = [
      { name: "value", visible: true },
      { name: "contactAttributeOptionMap", visible: true },
      { name: "subscriptionListMap", visible: true },
      { name: "subscriptionType", visible: true },
      { name: "trueMap", visible: true },
      { name: "falseMap", visible: true },
    ];
  }

  SurveyQuestionEditorDefinition.definition["itemvalue[]@choices"].tabs = [
    { name: "general", visible: true },
  ];

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("itemvalue", {
      name: "leadAttributeOptionMap",
      type: "dropdown",
      displayName: i18next.t("NEW_FORM_EDITOR.ITEM_VALUE.LEAD_OPTION_MAP"),
      isSerializable: true,
      showMode: "form",
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        // eslint-disable-next-line no-underscore-dangle
        const selectedAttribute = obj?._locOwner?.leadMap;
        if (selectedAttribute) {
          choicesCallback(LeadOptionsAttributes[selectedAttribute]);
        }
      },
    });
  }
  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "contactAttributeOptionMap",
    type: "dropdown",
    displayName: i18next.t("NEW_FORM_EDITOR.ITEM_VALUE.CONTACT_OPTION_MAP"),
    isSerializable: true,
    showMode: "form",
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      // eslint-disable-next-line no-underscore-dangle
      const selectedAttribute = obj?._locOwner?.contactMap;
      if (selectedAttribute) {
        choicesCallback(ContactOptionsAttributes[selectedAttribute]);
      }
    },
  });
  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "subscriptionType",
    type: "dropdown",
    displayName: i18next.t("NEW_FORM_EDITOR.ITEM_VALUE.SUBSCRIPTION_TYPE"),
    isSerializable: true,
    showMode: "form",
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      choicesCallback(SubscriptionType);
    },
  });
  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "subscriptionListMap",
    type: "dropdown",
    displayName: i18next.t("NEW_FORM_EDITOR.ITEM_VALUE.SUBSCRIPTION_LIST"),
    isSerializable: true,
    showMode: "form",
    dependsOn: ["subscriptionType"],
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      if (obj.subscriptionType === "email") {
        SetMapSubscriptionAttributes(choicesCallback, formStateParams, "email");
      }
      if (obj.subscriptionType === "phone") {
        SetMapSubscriptionAttributes(choicesCallback, formStateParams, "phone");
      }
    },
  });

  ItemValueCommon(formStateParams);
}

export default ItemValue;
