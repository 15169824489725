import FormStateParams from "../interfaces/FormStateParams";

export default function PublishCustomFields(rp: FormStateParams) {
  let url = `${process.env.REACT_APP_API_URL}/api/surveymetadata/clear/${rp.accountKey}?`;
  url += new URLSearchParams({
    accountKey: rp.accountKey,
    sessionId: rp.sessionId,
    userlcid: rp.userlcid,
    orgname: rp.orgname,
    userid: rp.userid,
  });

  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
  };
  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response;
    }
    throw new Error(response.statusText);
  });
}
