import FormStateParams from "../../interfaces/FormStateParams";
import { DialogType } from "../../../common/enums/DialogType";
import i18next from "../../../common/localization/i18n";

async function GetLeadContactAttributeOptionsThen(
  data: any,
  surveyElement: any,
  value: any,
  setChoices: any,
  routeParams: FormStateParams,
  entityType: string,
  dialog: any,
) {
  const choices = Object.assign(surveyElement.dataChoices || surveyElement.choices);
  if ((value === null || value === undefined) && choices?.length === 0) {
    surveyElement.choices = [];
  } else {
    if (surveyElement.survey?.creator?.state === "modified") {
      if (entityType === "lead") {
        choices.forEach((choice: any) => {
          choice.leadAttributeOptionMap = null;
        });
      }
      if (entityType === "contact") {
        choices.forEach((choice: any) => {
          choice.contactAttributeOptionMap = null;
        });
      }
    }

    if (data?.options?.length === 0) {
      return;
    }
    const cc: any = [];
    cc.push({ value: null });
    (data as any).options.forEach((c: { value: string; label: string }) => {
      cc.push({ value: c.value, text: c.label });
      if (entityType === "lead") {
        choices.push({ value: c.value, text: c.label, leadAttributeOptionMap: c.value });
      }
      if (entityType === "contact") {
        choices.push({ value: c.value, text: c.label, contactAttributeOptionMap: c.value });
      }
    });
    setChoices(value, cc);

    if (
      surveyElement.survey?.creator?.state === "modified" &&
      choices?.length > 0 &&
      value !== null
    ) {
      if (
        await dialog(
          DialogType.Confirm,
          i18next.t("NEW_FORM_EDITOR.MAP_SELECTED_OPTIONS_DIALOG.TITLE"),
          i18next.t("NEW_FORM_EDITOR.MAP_SELECTED_OPTIONS_DIALOG.TEXT"),
          i18next.t("NEW_FORM_EDITOR.MAP_SELECTED_OPTIONS_DIALOG.CONTINUE"),
        )
      ) {
        surveyElement.choices = choices;
      }
    }
  }
}

export default GetLeadContactAttributeOptionsThen;
