// eslint-disable-next-line max-classes-per-file
import * as SurveyCore from "survey-core";
import { ReactQuestionFactory, SurveyQuestionElementBase } from "survey-react-ui";
import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import SetContactMaxLengthAndValue from "./common/SetContactMaxLengthAndValue";
import SetLeadMaxLengthAndValue from "./common/SetLeadMaxLengthAndValue";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import SetMapContactAttributes from "../helpers/SetMapContactAttributes";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import SetMapLeadAttributes from "../helpers/SetMapLeadAttributes";

const CUSTOM_TYPE = "datetimepicker";

export class QuestionDateTimePickerModel extends SurveyCore.Question {
  // eslint-disable-next-line class-methods-use-this
  getType() {
    return CUSTOM_TYPE;
  }

  get dateTimePickerType() {
    return this.getPropertyValue("dateTimePickerType");
  }

  set dateTimePickerType(val) {
    this.setPropertyValue("dateTimePickerType", val);
  }

  get dateFormat() {
    return this.getPropertyValue("dateFormat");
  }

  set dateFormat(val) {
    if (this.getPropertyValue("dateFormat") !== val && this.value) {
      this.setPropertyValue("defaultValue", null);
      this.setPropertyValue("value", null);
    }
    this.setPropertyValue("dateFormat", val);
  }

  get timeFormat() {
    return this.getPropertyValue("timeFormat");
  }

  set timeFormat(val) {
    this.setPropertyValue("timeFormat", val);
  }
}

// A class that renders questions of the new type in the UI
export class SurveyQuestionDateTimePicker extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  get dateFormat() {
    return this.question.dateFormat;
  }

  get type() {
    return this.question.dateTimePickerType;
  }

  get pickerDateTimeValue() {
    if (!this.question.value) {
      return null;
    }

    this.question.pickerDateTimeValue = moment(
      this.question.value,
      this.getDateTimeFormat(),
    ).toDate();
    return this.question.pickerDateTimeValue;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue("readOnly") || this.question.isDesignMode
      ? { pointerEvents: "none" }
      : undefined;
  }

  getDateTimeFormat = () => {
    let dateFormat = `${this.question.dateFormat
      .replace("dd", "DD")
      .replace("mm", "MM")
      .replace("yyyy", "YYYY")}`;
    if (this.question.dateTimePickerType === "DateTime") {
      dateFormat += ` ${this.question.timeFormat}`.replace("aa", "A");
    }

    return dateFormat;
  };

  handleDateChange = (date: Date) => {
    this.question.pickerDateTimeValue = date;
    this.question.value = moment(new Date(date)).format(this.getDateTimeFormat()).toString();
  };

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  renderDate(type: any) {
    if (type === "Date") {
      return (
        <DatePicker
          selected={this.pickerDateTimeValue}
          dateFormat={this.dateFormat}
          yearDropdownItemNumber={105}
          scrollableYearDropdown
          showMonthDropdown
          showYearDropdown
          onChange={(date: Date) => {
            this.handleDateChange(date);
          }}
        />
      );
    }
    return (
      <DatePicker
        selected={this.pickerDateTimeValue}
        dateFormat={`${this.question.dateFormat} ${this.question.timeFormat}`}
        showTimeSelect
        yearDropdownItemNumber={105}
        scrollableYearDropdown
        showMonthDropdown
        showYearDropdown
        timeFormat={this.question.timeFormat}
        onChange={(date: Date) => {
          this.handleDateChange(date);
        }}
      />
    );
  }

  renderElement() {
    // @ts-ignore
    return <div style={this.style}>{this.renderDate(this.type)}</div>;
  }
}

export function DateTimeControl(formStateParams: FormStateParams) {
  SurveyCore.Serializer.addClass(
    "datetimepicker",
    [
      {
        name: "dateTimePickerType",
        displayName: i18next.t("NEW_FORM_EDITOR.DATE_TIME.PICKER_TYPE"),
        default: "Slider",
        choices: [
          { value: "Date", text: i18next.t("NEW_FORM_EDITOR.DATE_TIME.DATE") },
          { value: "DateTime", text: i18next.t("NEW_FORM_EDITOR.DATE_TIME.DATE_TIME") },
        ],
        category: "general",
        visibleIndex: 2, // After the Name and Title
      },
      {
        name: "dateFormat",
        displayName: i18next.t("NEW_FORM_EDITOR.DATE_TIME.DATE_FORMAT"),
        choices: [
          { value: "dd/MM/yyyy", text: "dd/mm/yyyy" },
          { value: "MM/dd/yyyy", text: "mm/dd/yyyy" },
          { value: "yyyy/MM/dd", text: "yyyy/mm/dd" },
        ],
        category: "general",
        visibleIndex: 3,
      },
      {
        name: "timeFormat",
        displayName: i18next.t("NEW_FORM_EDITOR.DATE_TIME.TIME_FORMAT"),
        dependsOn: ["dateTimePickerType"],
        choices: [
          { value: "hh:mm aa", text: "12h" },
          { value: "HH:mm", text: "24h" },
        ],
        visibleIf: (obj: any) => {
          return obj.dateTimePickerType === "DateTime";
        },
        default: "hh:mm aa",
        category: "general",
        visibleIndex: 4,
      },
    ],
    function () {
      return new QuestionDateTimePickerModel("");
    },
    "question",
  );

  SurveyCore.ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    return new QuestionDateTimePickerModel(name);
  });

  ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return React.createElement(SurveyQuestionDateTimePicker, props);
  });

  SurveyCore.Serializer.addProperty("datetimepicker", {
    name: "crmMaxLength",
    type: "number",
    default: 1,
    isSerializable: true,
    visible: false,
  });
  SurveyCore.Serializer.addProperty("datetimepicker", {
    name: "crmContactMaxLength",
    type: "number",
    default: 1,
    isSerializable: true,
    visible: false,
  });
  SurveyCore.Serializer.addProperty("datetimepicker", {
    name: "crmLeadMaxLength",
    type: "number",
    default: 1,
    isSerializable: true,
    visible: false,
  });

  SurveyCore.Serializer.addProperty("datetimepicker", {
    name: "contactMap",
    isSerializable: true,
    visible: false,
    onSetValue: (obj: any, value: any) => {
      if (!obj.getPropertyValue("contactMapTemp")) {
        SetContactMaxLengthAndValue(obj, value);
      }
    },
    visibleIndex: 1,
  });

  SurveyCore.Serializer.addProperty("datetimepicker", {
    name: "contactMapTemp",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
    isRequired: false,
    type: "dropdown",
    dependsOn: ["dateTimePickerType"],
    isSerializable: false,
    category: "toolboxMapperCategory",
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    onSetValue: (surveyElement: any, value: any) => {
      SetContactMaxLengthAndValue(surveyElement, value);
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      if (obj.dateTimePickerType === "Date") {
        SetMapContactAttributes("date", choicesCallback, formStateParams, obj);
      }
      if (obj.dateTimePickerType === "DateTime") {
        SetMapContactAttributes("dateTime", choicesCallback, formStateParams, obj);
      }
    },
  });

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("datetimepicker", {
      name: "leadMapTemp",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "dropdown",
      dependsOn: ["dateTimePickerType"],
      isSerializable: false,
      isLightSerializable: false,
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      onSetValue: (surveyElement: any, value: any) => {
        SetLeadMaxLengthAndValue(surveyElement, value);
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        if (obj.dateTimePickerType === "Date") {
          SetMapLeadAttributes("date", choicesCallback, formStateParams, obj);
        }
        if (obj.dateTimePickerType === "DateTime") {
          SetMapLeadAttributes("dateTime", choicesCallback, formStateParams, obj);
        }
      },
    });

    SurveyCore.Serializer.addProperty("datetimepicker", {
      name: "leadMap",
      isSerializable: true,
      visible: false,
      onSetValue: (obj: any, value: any) => {
        if (!obj.getPropertyValue("leadMapTemp")) {
          SetLeadMaxLengthAndValue(obj, value);
        }
      },
      visibleIndex: 1,
    });
  }

  SurveyCore.Serializer.addProperty("datetimepicker", {
    name: "overrideInCrmMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "boolean",
    categoryIndex: 8,
  });
}
