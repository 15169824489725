import * as SurveyCore from "survey-core";
import i18next from "../../common/localization/i18n";

export default function Email() {
  if (!SurveyCore.Serializer.findClass("email_field")) {
    SurveyCore.ComponentCollection.Instance.add({
      name: "email_field",
      iconName: "icon-email",
      title: i18next.t("NEW_SUBSCRIPTIONS_EDITOR.EMAIL.EMAIL"),
      elementsJSON: [
        {
          titleLocation: "hidden",
          type: "text",
          inputType: "email",
        },
      ],
    });
  }
}
