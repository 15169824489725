function OnModified(sender: any, options: any) {
  if (options.type === "THEME_MODIFIED") {
    if (
      options.value.backgroundImage === undefined &&
      sender.JSON["backgroundImage"] !== undefined
    ) {
      const value = sender.JSON;
      delete value["backgroundImage"];
      sender.JSON = value;
    }
  }
}

export default OnModified;
