import * as SurveyCore from "survey-core";
import SetContactMaxLengthAndValue from "./common/SetContactMaxLengthAndValue";
import SetLeadMaxLengthAndValue from "./common/SetLeadMaxLengthAndValue";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import SetMapContactAttributes from "../helpers/SetMapContactAttributes";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import SetMapLeadAttributes from "../helpers/SetMapLeadAttributes";

function SingleInput(formStateParams: FormStateParams) {
  SurveyCore.Serializer.addProperty("text", {
    name: "crmMaxLength",
    type: "number",
    default: 1,
    isSerializable: true,
    visible: false,
  });
  SurveyCore.Serializer.addProperty("text", {
    name: "crmContactMaxLength",
    type: "number",
    default: 1,
    isSerializable: true,
    visible: false,
  });
  SurveyCore.Serializer.addProperty("text", {
    name: "crmLeadMaxLength",
    type: "number",
    default: 1,
    isSerializable: true,
    visible: false,
  });
  SurveyCore.Serializer.addProperty("text", {
    name: "contactMap",
    isSerializable: true,
    visible: false,
    categoryIndex: 8,
    onSetValue: (obj: any, value: any) => {
      if (!obj.getPropertyValue("contactMapTemp")) {
        SetContactMaxLengthAndValue(obj, value);
      }
    },
  });
  SurveyCore.Serializer.addProperty("text", {
    name: "leadMap",
    isSerializable: true,
    visible: false,
    categoryIndex: 8,
    onSetValue: (obj: any, value: any) => {
      if (!obj.getPropertyValue("leadMapTemp")) {
        SetLeadMaxLengthAndValue(obj, value);
      }
    },
  });

  SurveyCore.Serializer.getProperty("text", "inputType").onSetValue = (surveyElement, value) => {
    surveyElement.clearErrors();
    surveyElement.crmFieldType = value;
    surveyElement.inputMask = "none";
    surveyElement.inputType = value;
  };

  const defaultValueProp = SurveyCore.Serializer.getProperty("text", "defaultValue");
  defaultValueProp.dependsOn = ["inputType"];
  defaultValueProp.visibleIf = (obj) => {
    return obj.inputType !== "email";
  };

  SurveyCore.Serializer.addProperty("text", {
    name: "contactMapTemp",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
    isRequired: false,
    dependsOn: ["inputType"],
    category: "toolboxMapperCategory",
    type: "dropdown",
    isSerializable: false,
    onSetValue: (obj: any, value: any) => {
      SetContactMaxLengthAndValue(obj, value);
    },
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    visibleIf: (obj: any) => {
      return (
        obj.inputType === "text" ||
        obj.inputType === "email" ||
        obj.inputType === "url" ||
        obj.inputType === "integer" ||
        obj.inputType === "decimal" ||
        obj.inputType === "money"
      );
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      obj.clearErrors();
      if (obj.inputType === "text") {
        SetMapContactAttributes("text", choicesCallback, formStateParams, obj);
      }
      if (obj.inputType === "email") {
        SetMapContactAttributes("email", choicesCallback, formStateParams, obj);
      }
      if (obj.inputType === "url") {
        SetMapContactAttributes("url", choicesCallback, formStateParams, obj);
      }
      if (obj.inputType === "integer") {
        SetMapContactAttributes("integer", choicesCallback, formStateParams, obj);
      }
      if (obj.inputType === "decimal") {
        SetMapContactAttributes("decimal", choicesCallback, formStateParams, obj);
      }
      if (obj.inputType === "money") {
        SetMapContactAttributes("money", choicesCallback, formStateParams, obj);
      }
    },
    visibleIndex: 1,
  });

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("text", {
      name: "leadMapTemp",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
      isRequired: false,
      dependsOn: ["inputType"],
      category: "toolboxMapperCategory",
      type: "dropdown",
      isSerializable: false,
      onSetValue: (obj: any, value: any) => {
        SetLeadMaxLengthAndValue(obj, value);
      },
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      visibleIf: (obj: any) => {
        return (
          obj.inputType === "text" ||
          obj.inputType === "email" ||
          obj.inputType === "url" ||
          obj.inputType === "integer" ||
          obj.inputType === "decimal" ||
          obj.inputType === "money"
        );
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        obj.clearErrors();
        if (obj.inputType === "text") {
          SetMapLeadAttributes("text", choicesCallback, formStateParams, obj);
        }
        if (obj.inputType === "email") {
          SetMapLeadAttributes("email", choicesCallback, formStateParams, obj);
        }
        if (obj.inputType === "url") {
          SetMapLeadAttributes("url", choicesCallback, formStateParams, obj);
        }
        if (obj.inputType === "integer") {
          SetMapLeadAttributes("integer", choicesCallback, formStateParams, obj);
        }
        if (obj.inputType === "decimal") {
          SetMapLeadAttributes("decimal", choicesCallback, formStateParams, obj);
        }
        if (obj.inputType === "money") {
          SetMapLeadAttributes("money", choicesCallback, formStateParams, obj);
        }
      },
      visibleIndex: 1,
    });
  }

  SurveyCore.Serializer.addProperty("text", {
    name: "overrideInCrmMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "boolean",
    categoryIndex: 8,
    visibleIf: (obj: any) => {
      return (
        obj.inputType === "text" ||
        obj.inputType === "email" ||
        obj.inputType === "url" ||
        obj.inputType === "integer" ||
        obj.inputType === "decimal" ||
        obj.inputType === "money"
      );
    },
  });
}

export default SingleInput;
