import Countries from "../data/Countries";

export default function SetCountryModel() {
  return Countries.map((c: any) => {
    return {
      value: { value: c.dialling_code, code: c.code },
      text: `${c.dialling_code} ${c.name}`,
      tooltip: `${c.name}`,
    };
  });
}
