import * as SurveyCore from "survey-core";
import { ValidateEmptyPhone, ValidatePhone } from "../validators/PhoneValidators";
import SetCountryModel from "../helpers/SetCountryModel";

export default function PhoneComponent(title: string) {
  SurveyCore.FunctionFactory.Instance.register("validateEmptyPhone", ValidateEmptyPhone);
  SurveyCore.FunctionFactory.Instance.register("validatePhone", ValidatePhone);

  SurveyCore.ComponentCollection.Instance.add({
    name: "phone_field",
    iconName: "icon-phone-field",
    title,
    onLoaded: (question) => {
      const countrycode = question.contentPanel.getQuestionByName("countrycode");
      countrycode.choices = SetCountryModel();
    },
    elementsJSON: [
      {
        type: "dropdown",
        name: "countrycode",
        titleLocation: "hidden",
        allowClear: false,
        minWidth: 200,
        maxWidth: 200,
        isDynamicChoices: true,
      },
      {
        type: "text",
        name: "phone",
        titleLocation: "hidden",
        startWithNewLine: false,
        minWidth: 200,
        enableIf: "{composite.countrycode} != null",
      },
    ],
  });
}
