import * as SurveyCore from "survey-core";
import * as SurveyCreatorCore from "survey-creator-core";
import i18next from "../localization/i18n";
import "survey-creator-core/i18n/french";
import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";
import FormStateParams from "../../form-editor/interfaces/FormStateParams";

export default function SetLocale(
  surveyCreator: any,
  formStateParams: FormStateParams | SubscriptionsStateParams,
  localeStringsEn: any,
  localeStringsFR: any,
) {
  if (formStateParams.userlcid === "1033") {
    i18next.changeLanguage("en");
    SurveyCreatorCore.editorLocalization.defaultLocale = "en";
    SurveyCore.surveyLocalization.defaultLocale = "en";

    // @ts-ignore
    SurveyCreatorCore.editorLocalization.locales.customlocale = localeStringsEn;
  } else {
    /// todo: change to french
    i18next.changeLanguage("en");
    SurveyCore.surveyLocalization.defaultLocale = "en";
    SurveyCreatorCore.editorLocalization.defaultLocale = "en";
    SurveyCore.surveyLocalization.defaultLocale = "en";
    // @ts-ignore
    SurveyCreatorCore.editorLocalization.locales.customlocale = localeStringsEn;
  }
  surveyCreator.locale = "customlocale";
  // surveyCreator.survey.locale = "customlocale";

  SurveyCore.settings.itemValueAlwaysSerializeText = true;
}
