export const SURVEY_OPTIONS = {
  showLogicTab: true,
  isAutoSave: false,
  syncSaveButtons: true,
  showTranslationTab: false,
  showJSONEditorTab: false,
  showThemeTab: true,
  logoPosition: "right",
  questionTypes: ["image", "html", "consent", "checkbox"],
};

export const DEFAULT_JSON = {
  captcha: true,
  logoPosition: "right",
};
