import * as SurveyCore from "survey-core";
import SetContactMaxLengthAndValue from "./common/SetContactMaxLengthAndValue";
import SetLeadMaxLengthAndValue from "./common/SetLeadMaxLengthAndValue";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import SetMapContactAttributes from "../helpers/SetMapContactAttributes";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import SetMapLeadAttributes from "../helpers/SetMapLeadAttributes";
import Countries from "../../common/data/Countries";

function CountryToolbox(formStateParams: FormStateParams) {
  if (!SurveyCore.Serializer.findClass("country_field")) {
    SurveyCore.ComponentCollection.Instance.add({
      name: "country_field",
      title: "Country",
      iconName: "icon-country",
      questionJSON: {
        displayName: i18next.t("NEW_FORM_EDITOR.QUESTION_TYPES.COUNTRY"),
        type: "dropdown",
        inputType: "dropdown",
        choices: Countries.map((c: { name: any }) => {
          return c.name;
        }),
      },
    });

    SurveyCore.Serializer.addProperty("country_field", {
      name: "crmMaxLength",
      type: "number",
      default: 1,
      isSerializable: true,
      visible: false,
    });
    SurveyCore.Serializer.addProperty("country_field", {
      name: "crmContactMaxLength",
      type: "number",
      default: 1,
      isSerializable: true,
      visible: false,
    });
    SurveyCore.Serializer.addProperty("country_field", {
      name: "crmLeadMaxLength",
      type: "number",
      default: 1,
      isSerializable: true,
      visible: false,
    });

    SurveyCore.Serializer.addProperty("country_field", {
      name: "contactMap",
      isSerializable: true,
      visible: false,
      categoryIndex: 8,
      onSetValue: (surveyElement: any, value: any) => {
        if (!surveyElement.getPropertyValue("contactMapTemp")) {
          SetContactMaxLengthAndValue(surveyElement, value);
        }
      },
    });

    SurveyCore.Serializer.addProperty("country_field", {
      name: "contactMapTemp",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "dropdown",
      isSerializable: false,
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      onSetValue: (surveyElement: any, value: any) => {
        SetContactMaxLengthAndValue(surveyElement, value);
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        obj.clearErrors();
        SetMapContactAttributes("text", choicesCallback, formStateParams, obj);
      },
      visibleIndex: 1,
      categoryIndex: 8,
    });

    if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
      SurveyCore.Serializer.addProperty("country_field", {
        name: "leadMap",
        isSerializable: true,
        visible: false,
        categoryIndex: 8,
        onSetValue: (obj: any, value: any) => {
          if (!obj.getPropertyValue("leadMapTemp")) {
            SetLeadMaxLengthAndValue(obj, value);
          }
        },
      });

      SurveyCore.Serializer.addProperty("country_field", {
        name: "leadMapTemp",
        displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
        isRequired: false,
        category: "toolboxMapperCategory",
        type: "dropdown",
        isSerializable: false,
        onPropertyEditorUpdate: (obj: any, editor: any) => {
          editor.allowClear = true;
        },
        onSetValue: (surveyElement: any, value: any) => {
          SetLeadMaxLengthAndValue(surveyElement, value);
        },
        choices: (obj: any, choicesCallback: any) => {
          if (!choicesCallback) return;
          obj.clearErrors();
          SetMapLeadAttributes("text", choicesCallback, formStateParams, obj);
        },
        visibleIndex: 1,
        categoryIndex: 8,
      });
    }

    SurveyCore.Serializer.addProperty("country_field", {
      name: "overrideInCrmMap",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "boolean",
      visibleIndex: 2,
      categoryIndex: 8,
    });
  }
}

export default CountryToolbox;
