function ChangeToolboxCategories(surveyCreator: any) {
  surveyCreator.toolbox.changeCategories([
    { name: "email_field", category: "First section" },
    { name: "phone_field", category: "First section" },
    { name: "checkbox", category: "First section" },
    { name: "image", category: "First section" },
    { name: "html", category: "First section" },
    { name: "consent", category: "First section" },
  ]);
}

export default ChangeToolboxCategories;
