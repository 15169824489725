import i18next from "../localization/i18n";

const questionMap: Record<string, string> = {
  text: "SINGLE_INPUT",
  checkbox: "CHECKBOX",
  radiogroup: "RADIO_GROUP",
  dropdown: "LIST",
  comment: "COMMENT",
  rating: "RATING",
  imagepicker: "IMAGE_PICKER",
  boolean: "BOOLEAN",
  html: "HTML",
  file: "FILE",
  image: "IMAGE",
  datetimepicker: "DATE_TIME_PICKER",
  country_field: "COUNTRY",
  phone_field: "PHONE",
  email_field: "EMAIL",
  tagbox: "MULTISELECT",
  consent: "CONSENT",
  subscription_list: "SUBSCRIPTION_LIST",
};

function GetQuestionName(translationsPrefix: string, key: string) {
  return i18next.t(`${translationsPrefix}.QUESTION_TYPES.${questionMap[key]}`);
}

export default function SetQuestionNameAndTitle(
  sender: any,
  options: any,
  translationsPrefix: string,
  questionType: string,
) {
  if (options.reason !== "ELEMENT_COPIED") {
    options.question.title = `Field ${sender.survey.getAllQuestions().length}`;
  }
  const questionName = GetQuestionName(translationsPrefix, questionType);
  const allQuestions = sender.survey
    .getAllQuestions()
    .filter((q: any) => q.getType() === questionType);
  let elementIndex = allQuestions.length;
  if (options.reason === "DROPPED_FROM_TOOLBOX" && options.page === null) {
    elementIndex += 1;
  }
  options.question.title = `${questionName} ${elementIndex}`.trim();
  options.question.name = `${questionName} ${elementIndex}`.trim();
}
