import "../App.css";
import { ErrorBoundary } from "react-error-boundary";
import { useEffect } from "react";
import { LoadingProvider } from "../common/providers/LoadingSpinner";
import SubscriptionCreator from "./components/SubscriptionCreator";
import FallbackRender from "../common/functions/FallbackRender";

function SubscriptionsEditor({ title }: any) {
  useEffect(() => {
    document.title = title;
  }, []);
  return (
    <div className="App">
      <LoadingProvider>
        <ErrorBoundary fallbackRender={FallbackRender}>
          <SubscriptionCreator />
        </ErrorBoundary>
      </LoadingProvider>
    </div>
  );
}

export default SubscriptionsEditor;
