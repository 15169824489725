import GetContactAttributes from "../api/GetContactAttributes";
import ContactLeadAttributeModel from "../interfaces/ContactLeadAttributeModel";
import FormStateParams from "../interfaces/FormStateParams";

let emailModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let textModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let listModel: { value: string; text: string }[] | undefined;
let multiList: { value: string; text: string }[] | undefined;
let urlModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let dateModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let textAreaModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let dateTimeModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let integerModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let decimalModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let moneyModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let phoneModel: { value: { value: string; length?: number }; text: string }[] | undefined;
let o: any;

function SetModel(type: string, data: [ContactLeadAttributeModel]) {
  const model = data.map((c: ContactLeadAttributeModel) => {
    return { value: { value: c.schemaName, length: c.length }, text: c.displayName };
  });

  return model;
}

function SetListModel(type: string, data: [ContactLeadAttributeModel]) {
  const model = data.map((c: ContactLeadAttributeModel) => {
    return { value: c.schemaName, text: c.displayName };
  });

  return model;
}
function SetMapContactAttributes(
  fieldType: string,
  choicesCallback: any,
  routeParams: FormStateParams,
  obj: any = null,
) {
  o = null;
  if (obj) {
    o = obj;
  }
  if (fieldType === "email" && emailModel) {
    choicesCallback(emailModel);
    return;
  }
  if (fieldType === "text" && textModel) {
    choicesCallback(textModel);
    return;
  }
  if (fieldType === "list" && listModel) {
    choicesCallback(listModel);
    return;
  }
  if (fieldType === "multiList" && multiList) {
    choicesCallback(multiList);
    return;
  }
  if (fieldType === "date" && dateModel) {
    choicesCallback(dateModel);
    return;
  }
  if (fieldType === "url" && urlModel) {
    choicesCallback(urlModel);
    return;
  }
  if (fieldType === "money" && moneyModel) {
    choicesCallback(moneyModel);
    return;
  }
  if (fieldType === "integer" && integerModel) {
    choicesCallback(integerModel);
    return;
  }
  if (fieldType === "decimal" && decimalModel) {
    choicesCallback(decimalModel);
    return;
  }
  if (fieldType === "textArea" && textAreaModel) {
    choicesCallback(textAreaModel);
    return;
  }
  if (fieldType === "dateTime" && dateTimeModel) {
    choicesCallback(dateTimeModel);
    return;
  }
  if (fieldType === "phone" && phoneModel) {
    choicesCallback(phoneModel);
    return;
  }
  GetContactAttributes(routeParams, fieldType)
    ?.then((data: [ContactLeadAttributeModel]) => {
      if (!data || data.length < 1) {
        choicesCallback([{}]);
      } else {
        if (fieldType === "email") {
          emailModel = SetModel(fieldType, data);
          choicesCallback(emailModel);
        }
        if (fieldType === "text") {
          textModel = SetModel(fieldType, data);
          choicesCallback(textModel);
        }
        if (fieldType === "list") {
          listModel = SetListModel(fieldType, data);
          choicesCallback(listModel);
        }
        if (fieldType === "multiList") {
          multiList = SetListModel(fieldType, data);
          choicesCallback(multiList);
        }
        if (fieldType === "url") {
          urlModel = SetModel(fieldType, data);
          choicesCallback(urlModel);
        }
        if (fieldType === "date") {
          dateModel = SetModel(fieldType, data);
          choicesCallback(dateModel);
        }
        if (fieldType === "textArea") {
          textAreaModel = SetModel(fieldType, data);
          choicesCallback(textAreaModel);
        }
        if (fieldType === "dateTime") {
          dateTimeModel = SetModel(fieldType, data);
          choicesCallback(dateTimeModel);
        }
        if (fieldType === "money") {
          moneyModel = SetModel(fieldType, data);
          choicesCallback(moneyModel);
        }
        if (fieldType === "integer") {
          integerModel = SetModel(fieldType, data);
          choicesCallback(integerModel);
        }
        if (fieldType === "decimal") {
          decimalModel = SetModel(fieldType, data);
          choicesCallback(decimalModel);
        }
        if (fieldType === "phone") {
          phoneModel = SetModel(fieldType, data);
          choicesCallback(phoneModel);
        }
      }
    })
    .catch(() => {
      o.clearErrors();
      o.addError("Server error while loading mappings.");
    });
}

export function ClearContactAttributes() {
  emailModel = undefined;
  textModel = undefined;
  listModel = undefined;
  multiList = undefined;
  urlModel = undefined;
  dateModel = undefined;
  textAreaModel = undefined;
  dateTimeModel = undefined;
  integerModel = undefined;
  decimalModel = undefined;
  moneyModel = undefined;
  phoneModel = undefined;
}

export default SetMapContactAttributes;
