import * as SurveyCreatorCore from "survey-creator-core";

function SettingsPanel() {
  const surveyProps = SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.survey.properties;
  // @ts-ignore
  const move = (from: any, to: any, arr: any) => {
    const item = arr.splice(from, 1)[0];
    arr.splice(to, 0, item);
  };
  const deleteItem = (index: any, arr: any) => {
    arr.splice(index, 1);
  };
  // @ts-ignore
  const startSurveyIndex = surveyProps.findIndex((el: any) => el.name === "startSurveyText");
  deleteItem(startSurveyIndex, surveyProps);
  // @ts-ignore
  const showPreviewBeforeCompleteIndex = surveyProps.findIndex(
    (el: any) => el.name === "showPreviewBeforeComplete",
  );
  // @ts-ignore
  const moveToIndex = surveyProps.findIndex((el: any) => el.name === "pageNextText");
  move(showPreviewBeforeCompleteIndex, moveToIndex, surveyProps);
  // @ts-ignore
  const completeTextIndex = surveyProps.findIndex((el: any) => el.name === "completeText");
  move(completeTextIndex, 0, surveyProps);
}

export default SettingsPanel;
