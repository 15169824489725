import { SvgRegistry } from "survey-core";

export default function Icons() {
  SvgRegistry.registerIconFromSvg(
    "email",
    `<svg viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg">
    <path d="M16 3V13H0V3H16ZM1.11719 4L8 7.44531L14.8828 4H1.11719ZM15 12V5.0625L8 8.55469L1 5.0625V12H15Z" />
  </svg>`,
  );
}
