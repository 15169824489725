import React from "react";
import FormStateParams from "../../interfaces/FormStateParams";
import PublishCustomFields from "../../api/PublishCustomFields";
import { ClearContactAttributes } from "../../helpers/SetMapContactAttributes";
import { ClearLeadAttributes } from "../../helpers/SetMapLeadAttributes";
import { DialogType } from "../../../common/enums/DialogType";

export default function PublishCustomFieldsButton(
  surveyCreator: any,
  routeParams: FormStateParams,
  setLoading: any,
  dialog: any,
) {
  setLoading(true);
  PublishCustomFields(routeParams)
    ?.then(() => {
      ClearContactAttributes();
      ClearLeadAttributes();

      const selected = surveyCreator.selectedElement;
      if (selected.isQuestion) {
        surveyCreator.selectElement(null);
        surveyCreator.selectElement(selected);
        selected.focus();
      }

      // @ts-ignore
      setLoading(false);
    })
    .catch(async (e: any) => {
      // @ts-ignore
      setLoading(false);
      if (e.message === "Unauthorized") {
        if (await dialog(DialogType.Confirm, "Error", "Ooops! Unauthorized error")) {
          return <>getFormJson error</>;
        }
      } else if (await dialog(DialogType.Confirm, "Error", "Publishing server error")) {
        return <>getFormJson error</>;
      }
      return <>getFormJson error</>;
    });
}
