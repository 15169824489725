import * as SurveyCore from "survey-core";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import GetLeadContactAttributeOptions from "../api/GetLeadContactAttributeOptions";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import GetLeadAttributes from "../api/GetLeadAttributes";
import ContactLeadAttributeModel from "../interfaces/ContactLeadAttributeModel";
import GetContactAttributes from "../api/GetContactAttributes";

function BooleanToolbox(formStateParams: FormStateParams) {
  let contactAttributes: { value: string; text: string }[] | undefined;
  let leadAttributes: { value: string; text: string }[] | undefined;

  function setChoices(choicesCallback: any, objVal: string, entityName: string) {
    if (!choicesCallback) return;
    GetLeadContactAttributeOptions(formStateParams, entityName, objVal)?.then((data: any) => {
      const cc: any = [];
      (data as any).options.forEach((c: { value: string; label: string }) => {
        cc.push({ value: c.value, text: c.label });
      });
      choicesCallback(cc);
    });
  }

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("boolean", {
      name: "falseLeadMap:dropdown",
      // eslint-disable-next-line
      displayName: i18next.t("NEW_FORM_EDITOR.BOOLEAN.FALSE_LEAD_VALUE"),
      category: "choices",
      dependsOn: ["leadMap"],
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      choices: (obj: any, choicesCallback: any) => {
        setChoices(choicesCallback, obj.leadMap, "lead");
      },
    });
  }

  SurveyCore.Serializer.addProperty("boolean", {
    name: "falseContactMap:dropdown",
    // eslint-disable-next-line
    displayName: i18next.t("NEW_FORM_EDITOR.BOOLEAN.FALSE_CONTACT_VALUE"),
    category: "choices",
    dependsOn: ["contactMap"],
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    choices: (obj: any, choicesCallback: any) => {
      setChoices(choicesCallback, obj.contactMap, "contact");
    },
  });

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("boolean", {
      name: "trueLeadMap:dropdown",
      // eslint-disable-next-line
      displayName: i18next.t("NEW_FORM_EDITOR.BOOLEAN.TRUE_LEAD_VALUE"),
      category: "choices",
      dependsOn: ["leadMap"],
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      choices: (obj: any, choicesCallback: any) => {
        setChoices(choicesCallback, obj.leadMap, "lead");
      },
    });
  }

  SurveyCore.Serializer.addProperty("boolean", {
    name: "trueContactMap:dropdown",
    // eslint-disable-next-line
    displayName: i18next.t("NEW_FORM_EDITOR.BOOLEAN.TRUE_CONTACT_VALUE"),
    category: "choices",
    dependsOn: ["contactMap"],
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    choices: (obj: any, choicesCallback: any) => {
      setChoices(choicesCallback, obj.contactMap, "contact");
    },
  });

  SurveyCore.Serializer.findProperty("boolean", "valueTrue").visible = false;
  SurveyCore.Serializer.findProperty("boolean", "valueFalse").visible = false;

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("boolean", {
      name: "leadMap",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "dropdown",
      categoryIndex: 8,
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      onSetValue: (surveyElement: any, value: any) => {
        surveyElement.setPropertyValue("leadMap", value);
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        if (!leadAttributes) {
          GetLeadAttributes(formStateParams, "boolean")?.then(
            (data: [ContactLeadAttributeModel]) => {
              leadAttributes = data.map((c: ContactLeadAttributeModel) => {
                return { value: c.schemaName, text: c.displayName };
              });
              choicesCallback(leadAttributes);
            },
          );
        } else {
          choicesCallback(leadAttributes);
        }
      },
      visibleIndex: 1,
    });
  }

  SurveyCore.Serializer.addProperty("boolean", {
    name: "contactMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "dropdown",
    isSerializable: true,
    isLightSerializable: true,
    categoryIndex: 8,
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    onSetValue: (surveyElement: any, value: any) => {
      surveyElement.setPropertyValue("contactMap", value);
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      if (!leadAttributes) {
        GetContactAttributes(formStateParams, "boolean")?.then(
          (data: [ContactLeadAttributeModel]) => {
            contactAttributes = data.map((c: ContactLeadAttributeModel) => {
              return { value: c.schemaName, text: c.displayName };
            });
            choicesCallback(contactAttributes);
          },
        );
      } else {
        choicesCallback(contactAttributes);
      }
    },
    visibleIndex: 1,
  });

  SurveyCore.Serializer.addProperty("boolean", {
    name: "overrideInCrmMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "boolean",
    categoryIndex: 8,
  });
}

export default BooleanToolbox;
