import * as SurveyCore from "survey-core";
import { Question } from "survey-core";

export default function Consent(title: string) {
  if (!SurveyCore.Serializer.findClass("consent")) {
    const updateChoices = (question: any) => {
      const subscriptionList = question.contentPanel.getQuestionByName("consentItem");
      if (question.choices.length > 0) {
        subscriptionList.choices = question.choices.map(
          (val: any) => new SurveyCore.ItemValue(val.value, val.text),
        );
      }
      if (question.choices.length === 0) {
        subscriptionList.choices = question.choices.push(
          new SurveyCore.ItemValue("item1", "item1"),
        );
        question.propertyValueChanged("choices");
      }
      subscriptionList.minSelectedChoices = subscriptionList.choices.length;
    };

    SurveyCore.ComponentCollection.Instance.add({
      name: "consent",
      title,
      iconName: "icon-consent",
      elementsJSON: [
        {
          isCopied: true,
          name: "consentItem",
          type: "checkbox",
          titleLocation: "hidden",
          choices: [{ text: "item1", value: "item1" }],
        },
      ],
      onInit: () => {
        SurveyCore.Serializer.addProperty("consent", {
          name: "choices",
          category: "Choices",
          type: "itemvalues",
          isSerializable: true,
          visible: true,
        });
      },
      onLoaded: (question) => {
        // @ts-ignore
        updateChoices(question);
      },
      onPropertyChanged: (question: Question, propertyName) => {
        if (propertyName === "choices") {
          // @ts-ignore
          updateChoices(question);
        }
      },
      onItemValuePropertyChanged: (question, options) => {
        if (options.propertyName === "choices") {
          // @ts-ignore
          updateChoices(question, options);
        }
      },
    });
  }
}
